.admin-title1 {
    font-weight: 600;
    font-size: 24px;
    color: #344051;
}

.admin-title2 {
    font-weight: 600;
    font-size: 16px;
    color: #475467;
}

.admin-title3 {
    font-weight: 400;
    font-size: 14px;
    color: #475467;
}

.admin-title4 {
    font-weight: 100;
    font-size: 20px;
    color: #475467;
}

.box-icon-ctn {
    width: 18px;
    height: 18px;
    border-radius: 100%;
}

.content-container {
    background-color: #FAFAF9;
    border: 1px solid #E6E6E6;
    border-radius: 16px;
    width: 100%;
    align-self: center;
    height: 600px;
    position: relative;
    margin-top: 30px;
}